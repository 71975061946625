import { createContext, useState, useEffect } from 'react';

import { ACCESS_TOKEN } from '../constants/globals';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedToken = localStorage.getItem(ACCESS_TOKEN);
    return storedToken ? { token: storedToken } : {};
  });
  const [user, setUser] = useState({});

  useEffect(() => {
    if (auth?.token) {
      localStorage.setItem(ACCESS_TOKEN, auth.token);
    } else {
      localStorage.removeItem(ACCESS_TOKEN);
    }
  }, [auth]);

  return <AuthContext.Provider value={{ auth, setAuth, user, setUser }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
