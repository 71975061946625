import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import useAuth from './useAuth';
import { axiosPrivate } from '../api/axios';
import { ACCESS_TOKEN } from '../constants/globals';
const useAxiosPrivate = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        // Try to get token from auth state first, then from localStorage as fallback
        const token = auth?.token || localStorage.getItem(ACCESS_TOKEN);
        if (token && !config.headers['x-access-token']) {
          config.headers['x-access-token'] = token;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        // If token is invalid (401 or 403), logout and redirect to login
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          // Clear auth state and localStorage
          setAuth({});
          localStorage.removeItem(ACCESS_TOKEN);
          // Redirect to login
          navigate('/login', { replace: true });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, setAuth, navigate]);

  return axiosPrivate;
};

export default useAxiosPrivate;
