import React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthenticatedLayout from './layouts/AuthenticatedLayout.js';
import EmptyLayout from './layouts/EmptyLayout.js';
import PersistLogin from './layouts/PersistLogin.js';
import CreditPurchases from './pages/CreditPurchases.jsx';
import Deliveries from './pages/Deliveries.jsx';
import Drivers from './pages/Drivers.jsx';
import LoginPage from './pages/Login.jsx';
import NotFound from './pages/NotFound.jsx';
import Unauthorized from './pages/Unauthorized.jsx';
import Users from './pages/Users.jsx';
import Withdrawals from './pages/Withdrawals.jsx';

function App() {
  return (
    <>
      <ToastContainer autoClose={3000} position="bottom-center" newestOnTop />
      <Routes>
        <Route element={<EmptyLayout />}>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="unauthorized" element={<Unauthorized />} />

          {/* <Route element={<PersistLogin />}> */}
          <Route element={<AuthenticatedLayout />}>
            <Route index path="/control-panel/users" element={<Users />} />
            <Route index path="/control-panel/deliveries" element={<Deliveries />} />
            <Route index path="/control-panel/withdrawals" element={<Withdrawals />} />
            <Route index path="/control-panel/purchases" element={<CreditPurchases />} />
            <Route index path="/control-panel/drivers" element={<Drivers />} />
          </Route>
        </Route>
        {/* </Route> */}
      </Routes>
    </>
  );
}

export default App;
