import { useNavigate } from 'react-router-dom';

import useAuth from './useAuth';
import { axiosPrivate } from '../api/axios';
import { logout as logoutRequest } from '../api/services/Auth';
import { ACCESS_TOKEN } from '../constants/globals';

const useLogout = () => {
  const { setAuth, setUser } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await logoutRequest();
      setAuth({});
      setUser({});
      localStorage.removeItem(ACCESS_TOKEN);

      delete axiosPrivate.defaults.headers.common['x-access-token'];
      navigate('/login', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
  return logout;
};

export default useLogout;
